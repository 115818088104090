import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const FooterWrapper = styled.footer`
  background-color: var(--background);
  padding: 5px 0px 5px 0px;
  text-align: center;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 1rem;
    font-size: 0.7rem;
  }
`;

const Copyright = styled.p`
  color: var(--text);
  font-size: 0.8rem; // Slightly reduced font size
  margin: 0; // Remove default margin
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialLink = styled.a`
  color: var(--text);
  font-size: 1.2rem; // Slightly reduced icon size
  transition: color 0.3s ease;

  &:hover {
    color: var(--primary);
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterContent>
        <Copyright>&copy; {new Date().getFullYear()} Prasanga Dhakal. All rights reserved.</Copyright>
        <SocialLinks>
          <SocialLink href="https://www.linkedin.com/in/prasangadhakal/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </SocialLink>
          <SocialLink href="https://github.com/p-dhakal" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </SocialLink>
        </SocialLinks>
      </FooterContent>
    </FooterWrapper>
  );
}

export default Footer;

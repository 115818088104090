import React, { useState, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import ProjectCard from '../components/ProjectCard';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const ProjectsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 2rem 8rem;

  @media (max-width: 768px) {
    padding: 1rem 1rem 8rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 2rem;
  text-align: center;
  animation: ${fadeIn} 0.6s ease-out;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const FilterContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  background: transparent;
  color: var(--text);
  border: 1px solid var(--accent);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &.active {
    background: var(--accent);
    color: var(--background);
  }

  &:hover {
    background: ${props => !props.active && 'rgba(245, 158, 11, 0.1)'};
  }
`;

const projectsData = [
  {
    id: 1,
    title: "E-commerce Platform",
    description: "A full-stack e-commerce solution with React, Node.js, and MongoDB.",
    link: "https://github.com/yourusername/ecommerce-project",
    tags: ["React", "Node.js", "MongoDB"],
    image: "/path-to-image.jpg"
  },
  {
    id: 2,
    title: "Weather App",
    description: "Real-time weather application using OpenWeatherMap API and React.",
    link: "https://github.com/yourusername/weather-app",
    tags: ["React", "API Integration"]
  },
  {
    id: 3,
    title: "Task Management System",
    description: "A Kanban-style task management app with drag-and-drop functionality.",
    link: "https://github.com/yourusername/task-manager",
    tags: ["Vue.js", "Vuex", "Firebase"]
  },
  {
    id: 4,
    title: "Portfolio Website",
    description: "A responsive portfolio website showcasing my projects and skills.",
    link: "https://github.com/yourusername/portfolio",
    tags: ["React", "Styled Components"]
  }
];

function Projects() {
  const [activeFilter, setActiveFilter] = useState('All');
  
  // Get unique tags for filter buttons
  const allTags = useMemo(() => {
    const tags = new Set(['All']);
    projectsData.forEach(project => {
      project.tags.forEach(tag => tags.add(tag));
    });
    return Array.from(tags);
  }, []);

  // Filter projects based on active filter
  const filteredProjects = useMemo(() => {
    if (activeFilter === 'All') {
      return projectsData;
    }
    return projectsData.filter(project => 
      project.tags.includes(activeFilter)
    );
  }, [activeFilter]);

  const handleFilterClick = (tag) => {
    setActiveFilter(tag);
  };

  return (
    <ProjectsWrapper>
      <Title>My Projects</Title>
      <FilterContainer>
        {allTags.map(tag => (
          <FilterButton
            key={tag}
            className={activeFilter === tag ? 'active' : ''}
            onClick={() => handleFilterClick(tag)}
          >
            {tag}
          </FilterButton>
        ))}
      </FilterContainer>
      <ProjectGrid>
        {filteredProjects.map((project, index) => (
          <ProjectCard
            key={project.id}
            {...project}
            delay={index * 0.1}
          />
        ))}
      </ProjectGrid>
    </ProjectsWrapper>
  );
}

export default Projects;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px);
  text-align: center;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(to right, var(--primary), var(--accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const AnimatedSubtitleContainer = styled.div`
  width: 100%;
  max-width: 700px;
  height: auto;
  min-height: 3em;
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const AnimatedSubtitle = styled.div`
  font-size: 1.5rem;
  white-space: normal;
  position: relative;
  text-align: center;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  &::after {
    content: '|';
    animation: blink 0.7s infinite;
  }

  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
`;

const CTAButton = styled(Link)`
  background-color: transparent;
  color: var(--text);
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  border: 2px solid var(--accent);

  &:hover {
    background-color: rgba(var(--accent-rgb), 0.8);
    color: var(--text);
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    border-color: var(--accent);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;
  }
`;

function Home() {
  const [typedText, setTypedText] = useState('');
  const fullText = "A passionate developer creating amazing web experiences";

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < fullText.length) {
        setTypedText(fullText.slice(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, []);

  return (
    <HomeWrapper>
      <Title>Hi, I'm Prasanga</Title>
      <AnimatedSubtitleContainer>
        <AnimatedSubtitle>{typedText}</AnimatedSubtitle>
      </AnimatedSubtitleContainer>
      <CTAButton to="/projects">View My Work</CTAButton>
    </HomeWrapper>
  );
}

export default Home;

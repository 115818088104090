import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaReact, FaNodeJs, FaDatabase, FaCode } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const AboutWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 2rem 8rem;
  animation: ${fadeIn} 0.6s ease-out;

  @media (max-width: 768px) {
    padding: 1rem 1rem 8rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  color: var(--accent);
  margin-bottom: 1.5rem;
  font-family: 'Poppins', sans-serif;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: var(--text);
`;

const SkillsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 3rem;
`;

const SkillItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`;

const SkillIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;

  svg {
    fill: url(#gradient);
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const GradientDef = () => (
  <svg width="0" height="0">
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="var(--primary)" />
        <stop offset="100%" stopColor="var(--accent)" />
      </linearGradient>
    </defs>
  </svg>
);

const SkillName = styled.span`
  font-size: 1.1rem;
  color: var(--text);
`;

const fillBar = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const SkillsSection = styled.div`
  margin-top: 3rem;
`;

const SkillBarContainer = styled.div`
  margin-bottom: 1.5rem;
`;

const SkillLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  color: var(--text);
`;

const SkillBar = styled.div`
  width: 100%;
  background: var(--card-bg);
  height: 8px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.progress}%;
    background: linear-gradient(to right, var(--primary), var(--accent));
    animation: ${fillBar} 1.5s ease-out;
  }
`;

const skillsData = [
  { name: 'React', progress: 90, icon: <FaReact /> },
  { name: 'Node.js', progress: 85, icon: <FaNodeJs /> },
  { name: 'Databases', progress: 80, icon: <FaDatabase /> },
  { name: 'Full Stack', progress: 85, icon: <FaCode /> }
];

function About() {
  return (
    <AboutWrapper>
      <GradientDef />
      <Title>About Me</Title>
      <Subtitle>Passionate Full Stack Developer</Subtitle>
      <Paragraph>
        Hello! I'm Prasanga, a dedicated full stack developer with a passion for creating
        innovative and efficient web solutions. With a strong foundation in both front-end
        and back-end technologies, I strive to build seamless, user-friendly applications
        that make a difference.
      </Paragraph>
      <Paragraph>
        My journey in the world of programming began with a curiosity to understand how
        things work behind the scenes. This curiosity has driven me to continuously learn
        and adapt to new technologies, allowing me to stay at the forefront of web development.
      </Paragraph>
      <Paragraph>
        When I'm not coding, you can find me exploring new technologies, contributing to
        open-source projects, or sharing my knowledge through blog posts and mentoring.
        I believe in the power of community and collaboration to drive innovation in the
        tech industry.
      </Paragraph>
      <SkillsSection>
        <Subtitle>Technical Skills</Subtitle>
        <SkillsContainer>
          <SkillItem>
            <SkillIcon>
              <FaReact style={{ fill: 'url(#gradient)' }} />
            </SkillIcon>
            <span>React</span>
          </SkillItem>
          <SkillItem>
            <SkillIcon>
              <FaNodeJs style={{ fill: 'url(#gradient)' }} />
            </SkillIcon>
            <span>Node.js</span>
          </SkillItem>
          <SkillItem>
            <SkillIcon>
              <FaDatabase style={{ fill: 'url(#gradient)' }} />
            </SkillIcon>
            <span>Database</span>
          </SkillItem>
          <SkillItem>
            <SkillIcon>
              <FaCode style={{ fill: 'url(#gradient)' }} />
            </SkillIcon>
            <span>Full Stack</span>
          </SkillItem>
        </SkillsContainer>

        <div>
          {skillsData.map((skill, index) => (
            <SkillBarContainer key={index}>
              <SkillLabel>
                <span>{skill.name}</span>
                <span>{skill.progress}%</span>
              </SkillLabel>
              <SkillBar progress={skill.progress} />
            </SkillBarContainer>
          ))}
        </div>
      </SkillsSection>
    </AboutWrapper>
  );
}

export default About;
